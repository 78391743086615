/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em",
    img: "img"
  }, _provideComponents(), props.components), {Notes} = _components;
  if (!Notes) _missingMdxReference("Notes", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Convolutional_neural_network"
  }, "Convolutional neural networks"), " (CNNs) are a type\nof classical machine learning model often used in computer vision and image\nprocessing applications. The structure of CNNs consists of applying alternating\n", React.createElement(_components.em, null, "convolutional layers"), " (plus an activation function) and ", React.createElement(_components.em, null, "pooling layers"), " to an\ninput array, typically followed by some fully connected layers before the\noutput."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/5f8690bde9917fe8b9c8c806a7dd5452/cnn.svg",
    alt: "Convolutional Neural Networks"
  })), "\n", React.createElement(_components.p, null, "Convolutional layers work by sweeping across the input array and applying\ndifferent filters (often 2x2 or 3x3 matrices) block by block. These are used to\ndetect specific features of the image wherever they might appear. Pooling layers\nare then used to downsample the results of these convolutions to extract the\nmost relevant features and reduce the size of the data, making it easier to\nprocess in subsequent layers. Common pooling methods involve replacing blocks of\nthe data with their maximum or average values."), "\n", React.createElement(_components.p, null, "Quantum convolutional neural networks (QCNNs) were first introduced in ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1810.03787"
  }, "Cong et\nal. (2018)"), ". The structure of QCNNs is\nmotivated by that of CNNs:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/961b9be93a38a37064a639ec5370297a/qcnn.svg",
    alt: "Quantum convolutional neural networks"
  })), "\n", React.createElement(_components.p, null, "Here, convolutions are operations performed on neighbouring pairs of qubits —\nthey are parameterized unitary rotations, just like a regular ", React.createElement(_components.a, {
    href: "/qml/glossary/variational_circuit"
  }, "variational circuit"), "! These convolutions are followed by\npooling layers, which are effected by measuring a subset of the qubits, and\nusing the measurement results to control subsequent operations. The analogue of\na fully-connected layer is a multi-qubit operation on the remaining qubits\nbefore the final measurement. Parameters of all these operations are learned\nduring training."), "\n", React.createElement(_components.p, null, "One very natural application of QCNNs is classifying quantum states - for\nexample, the ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1810.03787"
  }, "original work"), " used them to\ndistinguish between different ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Topological_order"
  }, "topological phases"), ". QCNNs can also be used to ", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/2009.09423"
  }, "classify images"), " just like their classical counterparts."), "\n", React.createElement(Notes, {
    title: "Note",
    type: "success",
    text: `Quantum convolutional neural networks as presented here are different from
   *quanvolutional* neural networks. See the [demo about quanvolutional
   networks](/qml/demos/tutorial_quanvolution) to learn more!`
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
